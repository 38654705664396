@font-face {
  font-family: 'Harabara Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Harabara Bold'), url('~assets/fonts/Harabara.woff') format('woff');
}
body, html {
  font-family: 'Montserrat', sans-serif;
  height: 100%;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  min-height: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*, ::after, ::before {
  box-sizing: border-box;
}

.w-100 {
  width: 100%;
}

.content-wrapper {
  margin-top: 60px;
}

.ant-layout-content {
  background-image: url("~assets/images/bg-pattern.svg");
  background-size: cover;
}
