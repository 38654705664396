$mainGradient: linear-gradient(90deg, #001A51 -2.78%, #00235F 7.98%, #3453BE 39.55%, #61ACFF 74.68%, #95C7FF 90.35%), #C4C4C4;

$Montserrat: 'Montserrat', sans-serif;
$Roboto: 'Roboto', sans-serif;

// old colors
$primary-old: #00225E;
$primaryLight: #5B9DFF;
$textColor: #626A74;
$grey: #7788A6;

// new colors
$primary: #182859;
$primaryHover: #3A529F;

$buttonBg: #F3C169;

$sidebarInitial: #f0f1f1;
$sidebar: #182859;
$sidebarItem: #f5f6f6;
$sidebarItemHover: #d5ddf7;

$activeSelect: #3A529F;
