@import "~bootstrap/scss/mixins/breakpoints";

$grid-breakpoints: (
        xxs: 0,
        xs: 320,
        sm: 768px,
        md: 1200px
) !default;

$container-max-widths: (
        xs: 240px,
        sm: 385px,
        md: 960px
) !default;

$grid-gutter-width: 20px !default;
