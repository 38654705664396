@import "~styles/var";
@import "~bootstrap/scss/bootstrap-grid";

.title-container {
  display: flex;
  @include media-breakpoint-down(sm) {
    display: block;
  }
}

.simple-title {
  font-family: 'Harabara Bold', serif;
  font-weight: 700;
  font-size: 40px;
  color: $primary;
  margin-bottom: 40px;
  letter-spacing: 0.3em;
  line-height: 150%;
  @include media-breakpoint-down(sm) {
    font-size: 24px;
    line-height: 150%;
  }
  &.text-light {
    font-weight: 400;
    font-family: 'Montserrat';
    letter-spacing: initial;
    @include media-breakpoint-down(sm) {
      font-size: 20px;
    }
  }

  &.upper {
    text-transform: uppercase;
  }
  &.text-bold {
    font-weight: 700;
  }
  .text-bold {
    font-weight: 600;
  }
}

.content {
  font-size: 14px;
  line-height: 168%;
  color: $textColor;
  p {
    color: $primary;
    &:not(:last-of-type) {
      margin-bottom: 20px;
    }
  }
}
.content-section {
  padding-top: 60px;
  padding-bottom: 60px;
  @include media-breakpoint-down(sm) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.dark-section {
  background: $primary;
  color: #fff;
  .simple-title {
    color: #fff;
  }
  .content {
    color: #FBFCFD;
  }
}

.action-link {
  cursor: pointer;
  color: $primary;
  font-weight: 500;
  font-size: 14px;
  &:after {
    font-family: 'Font Awesome 5 Free';
    content: "\f061";
    font-weight: 900;
    display: inline-block;
    font-size: 14px;
    margin-left: 5px;
  }
  &.no-arrow {
    &:after {
      display: none;
    }
    &:before {
      display: none;
    }
  }
  &.left-arrow {
    &:after {
      display: none;
    }
    &:before {
      font-family: 'Font Awesome 5 Free';
      content: "\f060";
      font-weight: 900;
      display: inline-block;
      font-size: 14px;
      margin-left: 5px;
    }
  }
}

a {
  color: #3A529F;
}
