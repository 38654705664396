@import "~reset-css/sass/_reset.scss";
@import "~ng-zorro-antd/ng-zorro-antd.min.css";
@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~@ng-select/ng-select/themes/material.theme.css";
@import '~lightgallery/css/lightgallery-bundle.css';

@import './_columns';
@import './_alignments';

@import './base';
@import './components';
