@import "~styles/var";
@import "~styles/grid-config";

.ant-modal-footer {
  display: none !important;
}
.modal-form {
  padding: 50px 0;
  @include media-breakpoint-up(sm) {
    padding: 100px 0;
  }
  form {
    margin-top: 35px;
  }
}

.ant-modal {
  width: 800px !important;
}
