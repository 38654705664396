@import "~styles/var";

.mat-select-panel {
  margin-top: 50px;
  min-width: 100% !important;
  margin-left: 15px;
  background: #F3F4F4;
  border-width: 0 1px 1px 1px;
  border-style: solid;
  border-color: #DAE0EB;
  box-shadow: 0 4px 10px rgb(58 82 159 / 5%);
  padding: 6px !important;
}

.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: #DAE0EB;
  color: $primary;
}

.mat-option {
  color: #626A74;
  border-bottom: 1px solid #DAE0EB;
}

.mat-form-field-label-wrapper, .mat-select-value-text {
  font-family: 'Montserrat', serif;
}

.mat-form-field-type-mat-select {
  &.mat-focused, &:hover {
    .mat-form-field-outline-thick {
      color: $activeSelect;
    }

    .mat-form-field-label {
      color: $activeSelect;
    }

    .mat-select-min-line {
      color: $activeSelect;
    }

    .mat-form-field-underline {
      background: $activeSelect;
    }

    .mat-select-arrow {
      color: $activeSelect;
    }
  }
}
.mat-select-arrow {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  width: 15px !important;
  height: 18px !important;
  display: block;
  border: 0 !important;
  margin: 0 !important;
  &:after {
    content: "\f107";
  }
}
.ng-dropdown-panel {
  overflow: hidden;
  border-radius: 6px;
  padding: 6px;
  margin-top: -10px;
  background: #F3F4F4;
  border-width: 0 1px 1px 1px;
  border-style: solid;
  border-color: #DAE0EB;
}

.ng-select {
  padding-bottom: 0;
  margin-bottom: 1.25em;
  font-family: 'Montserrat', serif;

  * {
    cursor: pointer !important;
  }

  .ng-placeholder {
    color: red;
  }
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  color: #626A74;
  border-bottom: 1px solid #DAE0EB;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
  background: #DAE0EB;
  color: $primary;
}

.ng-select {
  &:hover, &:focus, &.ng-select-opened {
    .ng-select-container {
      &:after {
        border-color: $activeSelect;
      }
      .ng-arrow-wrapper {
        .ng-arrow {
          color: $activeSelect;
        }
      }

      .ng-value-container {
        .ng-placeholder {
          color: $activeSelect;
        }

        .ng-value {
          color: $activeSelect;
        }
      }
    }
  }
  .ng-select-container {
    .ng-arrow-wrapper {
      .ng-arrow {
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        min-width: 15px;
        border: none;
        &:after {
          content: "\f107";
        }
      }
    }
    .ng-value-container {
      .ng-value {
        color: rgba(0, 0, 0, .87);
      }
    }
  }
}

.ant-select {
  &:hover {
    .ant-select-arrow {
      color: $activeSelect;
    }
    .ant-select-selector {
      color: $activeSelect;
    }
  }
  .ant-select-selector {
    background: transparent !important;
  }
  .ant-select-arrow {
    i {
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      min-width: 15px;
      border: none;
      &:after {
        content: "\f107";
      }
    }
    svg {
      display: none;
    }
  }
}

.ant-select-dropdown {
  background: #F3F4F4;
  min-width: 120px;
  margin-left: -75%;
  margin-top: 10px;
  padding: 6px;
  border-radius: 6px;
}

.ant-select-item-option-selected {
  background-color: #DAE0EB !important;
  color: $primary;
}
.ant-select-item {
  background-color: #f5f5f5;
  border-bottom: 1px solid #DAE0EB;
  height: 36px;
  line-height: 36px;
  color: #626A74;
  font-size: 16px;
  &:not(.ant-select-item-option-selected):hover {
    background: rgba(0,0,0,0.04);
    color: rgba(0,0,0,0.87);
  }
}


.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: $primary;
}
