@import "~styles/var";

.ant-dropdown {
  margin-top: 20px;
  background: #F3F4F4;
  border: 1px solid #DAE0EB;
  box-shadow: 0 4px 10px rgba(58, 82, 159, 0.05);
  padding: 6px;
  min-width: 235px;

  ul {
    padding: 0;
    background: transparent;
    box-shadow: none;
  }

  li {
    padding: 0;
    display: block;
    height: 36px;
    font-weight: 500;
    font-size: 16px;
    align-items: center;
    color: #626A74;
    text-align: center;
    line-height: 36px;

    &:hover {
      background: #DAE0EB;
      color: $primary;
    }

    a {
      display: block;
      color: #626A74;
      &:hover {
        color: $primary;
      }
    }
  }
}
