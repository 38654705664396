@import "~styles/var";
@import "~bootstrap/scss/bootstrap-grid";

.ant-table {

  .ant-table-placeholder {
    display: none;
  }
}

.ant-table-thead > tr > th {
  background: none !important;
}

.ant-table-thead {
  tr {
    background: $primary;
  }

  & > tr {
    & > th {
      &.ant-table-cell {
        text-align: left !important;
      }

      border-radius: 0 !important;
      font-weight: 600;
      font-family: $Montserrat;
      color: #fff;
      height: 100%;
      border-bottom: 0;

      font-size: 16px;

      @include media-breakpoint-up(sm) {
        font-size: 18px;
      }

      &:nth-child(3) {
        min-width: 160px;
      }
    }
  }
}

.ant-table-tbody {
  td {
    font-family: $Montserrat;
    color: $textColor;

    &.bold {
      color: $primary;
      font-weight: 600;
    }
  }
}

.ant-table-tbody > tr > td, .ant-table-thead > tr > th {
  padding: 20px;
}

.ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
  background: #F3F5F9;
}

.airport-info {
  table {
    .mat-form-field {
      margin-bottom: -20px;
      //padding-bottom: 0;
    }
    tbody {
      tr {
        td {
          border-top: 1px solid #DAE0EB;
          border-bottom: 0;
        }
      }
      td {
        font-size: 14px;
        @include media-breakpoint-up(md) {
          font-size: 16px;
        }

        &:not(:first-of-type) {
          text-align: justify
        }

        &:first-of-type {
          @include media-breakpoint-up(md) {
            width: 30%;
          }
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    table {

      th {
        padding: 0 10px;
        height: 55px;
        font-size: 16px;
        vertical-align: middle;
      }

      tbody {
        display: block;

        tr, td {
          display: block;
          width: 100%;
        }

        td {
          padding: 5px 20px;

          &:first-of-type {
            border: none;
            padding-top: 15px;
          }

          &:last-of-type {
            padding-bottom: 15px;
          }
        }
      }
    }
  }
}

.quote-result-table {
  .ant-select-selection {
    margin-bottom: 0;
    min-width: 50px;
    border: none;
  }

  .text-center {
    text-align: center;
  }

  tr {
    th:nth-child(3), th:nth-child(4) {
      min-width: 70px;
    }
  }

  table {
    thead {
      tr:first-of-type {
        th {
          text-align: left;
        }
      }

      tr:not(:first-of-type) {
        background: rgba(223, 226, 234, 0.3);

        th {
          color: $primary;
          font-weight: 600;
          font-size: 16px;
        }
      }
    }
    tbody td {
      font-size: 16px;
      &:first-child, &:last-child {
        color: $primary;
        font-weight: 600;
      }
    }
  }
}

.ant-table {
  background: rgba(255, 255, 255, 0.3);
}

