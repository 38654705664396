@import "./grid-config";
@import "~bootstrap/scss/bootstrap-grid";
@import "~bootstrap/scss/utilities/_display";


@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.row {
  margin-left: -20px;
  margin-right: -20px;

  &.no-gutters {
    margin-right: 0;
    margin-left: 0;
  }
}

[class*="col-"], .col {
  padding-left: 20px;
  padding-right: 20px;
}

@include media-breakpoint-up(sm) {
  .container {
    max-width: 400px !important;
  }
  .xs-only {
    display: none !important;
  }
}

@include media-breakpoint-up(md) {
  .container {
    max-width: 960px !important;
  }
  .sm-only {
    display: none !important;
  }
}

@include media-breakpoint-down(sm) {
  .md-only {
    display: none !important;
  }
}

@include media-breakpoint-down(xs) {
  .hide-xs {
    display: none !important;
  }
  .container {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
  .row {
    margin-left: -4px;
    margin-right: -4px;
  }
  [class*="col-"], .col {
    padding-right: 4px;
    padding-left: 4px;
  }
  .no-gutters {
    margin-right: 0;
    margin-left: 0;
  }
  .md-only {
    display: none !important;
  }
}


