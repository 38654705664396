@import "~styles/var";

.btn {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  color: $primary;
  height: 50px;
  border-radius: 5px;
  padding: 0 20px;
  cursor: pointer;
  border: 0;
  transition: .2s ease-in;
  justify-content: center;

  &.btn-primary {
    background: $buttonBg;

    &:hover, &:focus {
      color: $primary;
      background: rgba($buttonBg, .7);
    }

    &:active {
      background: #f8ba4d;
    }
  }

  &.btn-outline-primary {
    background: transparent;
    border: 1px solid #3A529F;
    color: $primary;

    &:hover {
      background: $primary;
      color: #fff;
    }
  }
}
