@import "~styles/var";

.ant-form-item {
  margin-bottom: 20px;

  .ant-input {
    margin-bottom: 0;
  }

  .ant-select-selection {
    margin-bottom: 0;
  }

  textarea.ant-input {
    margin-bottom: 0 !important;
  }
}

.ant-input {
  border-radius: 4px !important;
  font-family: $Roboto;
  font-weight: 500;
  height: 37px;
  margin-bottom: 20px;
  border-color: #8691AF;

  &::placeholder {
    color: #7788A6 !important;
  }

  textarea.ant-input {
    border: 1px solid #8691AF;
  }
}

.ant-input-lg {
  height: 40px;
}

textarea.ant-input {
  margin-bottom: 20px !important;
}

.ant-select-selection {
  height: 37px;
  font-family: $Roboto;
  font-weight: 500;
  margin-bottom: 20px;
  border-color: #7788A6;
}

.ant-select-selection__rendered {
  line-height: 37px;
}

.ant-select-selection__placeholder {
  color: #7788A6;
}

.ant-checkbox-wrapper {
  font-family: $Roboto;

  span {
    font-size: 12px;
    color: #8691AF;
  }
}

.ant-form-explain {
  font-family: $Roboto;
  font-size: 12px;
  position: absolute;
}

.ant-checkbox-wrapper {
  &.ng-invalid.ng-dirty {
    span {
      color: red;
    }
  }
}

.user-list {
  .ant-input {
    margin-bottom: 0;
  }
}


.custom-aircraft {
  .ant-checkbox-wrapper span {
    font-weight: bold;
    font-size: 14px;
    color: #00225E;
    font-family: $Roboto;
  }
}

.ng-select {

  .ng-select-container {
    min-height: 40px;
    height: 45px;
  }

  .ng-input {
    input {
      padding: 0;
    }
  }
}

ng-select.ng-invalid.ng-touched + .mat-error {
  font-size: 11px;
  margin-top: -15px;
}

.table-select {
  max-width: 65px;
  .mat-form-field-wrapper {
    padding-bottom: 0;
  }
}

.profile-form {
  .mat-form-field-wrapper {
    margin-bottom: -1.25em;
  }
}

.form-container {
  .mat-checkbox {
    padding-bottom: 1.25em;
    display: block;
  }
}

.custom-aircraft {
  .mat-checkbox {
    padding-bottom: 0;
  }
}

.ng-select .ng-select-container {
	color: #595959;
}

.ng-clear-wrapper {
	display: none;
}


.no-margin {
  .ant-select-selection {
    margin-bottom: 0 !important;
  }
}

.mat-form-field {

  &.w-100 {
    width: 100%;
  }
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: none;
}

.ant-select-item {
  padding: 0;
  text-align: center;

}


.mat-form-field-appearance-outline {
  .mat-form-field-infix {
    padding: 6px 0 6px 0;
  }
  .mat-form-field-label {
    top: 10px;
    margin-top: 5px;
  }
  .mat-input-element {
    font-size: 16px;
  }

}

.mat-form-field {
  &.mat-focused, &:hover {
    .mat-form-field-outline-thick {
      color: $activeSelect;
    }
    .mat-form-field-label {
      color: $activeSelect;
    }
    .mat-form-field-underline {
      background: $activeSelect;
    }
  }
  &.mat-form-field-invalid {
    .mat-form-field-label {
      color: #f44336;
    }
  }
}

.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float {
  .mat-form-field-label {
    transform: translateY(-16px) scale(0.75);
  }
}
